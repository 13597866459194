import React from "react";

interface IconRobotProps {
  size?: number;
}

const IconRobot: React.FC<IconRobotProps> = ({ size = 47 }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="21" cy="21" r="21" fill="url(#paint0_linear)" />

        <g transform="translate(11 10)">
            <g id="Capa_1-2">
                <path
                    d="M9.77472 2.99797C10.595 3.15295 11.386 2.61586 11.5436 1.79554C11.6986 0.975217 11.1615 0.184286 10.3412 0.0266338C9.52087 -0.128346 8.72994 0.408739 8.57229 1.22906C8.41731 2.04939 8.95439 2.84032 9.77472 2.99797Z"
                    fill="white"
                />
                <path
                    d="M10.9399 7.56449C14.1544 7.98668 16.6447 10.7442 16.6447 14.0736C16.6447 17.6943 13.7001 20.6389 10.0768 20.6389C6.45346 20.6389 3.50884 17.6943 3.50884 14.0736C3.50884 10.779 5.94844 8.04279 9.11751 7.57785V5.40546C5.55297 5.79558 2.62974 8.34206 1.68115 11.7089C2.00714 11.8505 2.29573 12.1204 2.52285 12.4998C2.84083 13.0289 3.02253 13.7343 3.03322 14.4878C3.05192 15.7303 2.61905 16.7671 1.9537 17.2507C3.22561 20.4946 6.38666 22.7979 10.0768 22.7979C13.7669 22.7979 16.8665 20.5374 18.1625 17.3442C17.9033 17.1893 17.6681 16.9541 17.4784 16.6362C17.1604 16.1071 16.9787 15.4017 16.968 14.6481C16.9573 13.8946 17.1177 13.1758 17.4223 12.62C17.6895 12.1337 18.0502 11.8051 18.4617 11.6661C17.4891 8.29129 14.5311 5.75016 10.9399 5.39478V7.56716V7.56449Z"
                    fill="white"
                />
                <path
                    d="M1.76123 12.8045C1.68107 12.6709 1.59289 12.5587 1.50204 12.4705C1.35508 12.3262 1.2001 12.2487 1.06382 12.254C1.05313 12.254 1.04512 12.254 1.03443 12.254C0.820665 12.2808 0.572163 12.5079 0.382446 12.8579C0.125928 13.3309 -0.00767546 13.9508 0.000340735 14.6108C0.011029 15.2708 0.163337 15.88 0.430543 16.3263C0.641636 16.6736 0.908843 16.8847 1.12795 16.8767C1.30431 16.8687 1.50739 16.7217 1.68641 16.4625C1.96965 16.0591 2.20212 15.383 2.18876 14.52C2.17807 13.86 2.02577 13.2507 1.75856 12.8045H1.76123Z"
                    fill="white"
                />
                <path
                    d="M19.5706 12.8044C19.3595 12.4571 19.0923 12.246 18.8731 12.254C18.8625 12.254 18.8544 12.254 18.8438 12.254C18.7743 12.262 18.6995 12.2941 18.6273 12.3449C18.475 12.4464 18.32 12.6254 18.1918 12.8606C17.9353 13.3282 17.8017 13.9481 17.8097 14.6081C17.8204 15.2681 17.9727 15.8773 18.2399 16.3236C18.2987 16.4224 18.3628 16.5079 18.4296 16.5828C18.5979 16.7725 18.7796 16.882 18.9373 16.874C19.3889 16.85 20.0195 15.9281 20.0008 14.5172C19.9901 13.8572 19.8378 13.248 19.5706 12.8018V12.8044Z"
                    fill="white"
                />
                <path
                    d="M6.8488 11.7544C6.22621 11.7276 5.72119 12.6281 5.72119 13.7664C5.72119 14.9047 6.22621 15.8506 6.85147 15.8774C7.47674 15.9041 7.97909 15.0036 7.97909 13.8653C7.97909 12.727 7.47407 11.7811 6.8488 11.7544ZM7.23892 15.5674C6.96637 15.8212 6.57091 15.7438 6.35447 15.3964C6.13803 15.049 6.18079 14.56 6.45334 14.3062C6.72589 14.0523 7.12135 14.1298 7.33779 14.4772C7.55423 14.8246 7.51148 15.3136 7.23892 15.5674Z"
                    fill="white"
                />
                <path
                    d="M12.4868 11.618C11.8642 11.5913 11.3592 12.4918 11.3618 13.6301C11.3618 14.7684 11.8695 15.7116 12.4921 15.7383C13.1147 15.7651 13.6197 14.8646 13.617 13.7263C13.617 12.588 13.1094 11.6447 12.4868 11.618ZM12.8555 15.4818C12.5803 15.7303 12.1848 15.6502 11.9737 15.2974C11.7626 14.9474 11.8134 14.4584 12.0886 14.2099C12.3639 13.9614 12.7593 14.0416 12.9704 14.3943C13.1815 14.747 13.1307 15.2333 12.8555 15.4818Z"
                    fill="white"
                />
                <path
                    d="M10.3331 7.51371V3.74609H9.72656V7.51905C9.84413 7.51371 9.9617 7.50836 10.0793 7.50836C10.1648 7.50836 10.2503 7.51103 10.3331 7.51371Z"
                    fill="white"
                />
            </g>
        </g>

        <defs>
            <linearGradient id="paint0_linear" x1="0" y1="42" x2="42" y2="42" gradientUnits="userSpaceOnUse">
                <stop stopColor="#38ADC7" />
                <stop offset="1" stopColor="#0C56C0" />
            </linearGradient>
        </defs>
    </svg>
);

export default IconRobot;
