const styles = {
    global: {
        body: {
            color: "#000",
            textAlign: "center",
            fontFamily: "Sansation-Regular, sans-serif",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
        },
        "*": {
            boxSizing: "border-box",
        },
    },
};
  
export default styles;
  