import React from "react";
import { Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface SectionTitleProps {
    title: string;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ title }) => {
    const { t } = useTranslation();

    return (
        <Text fontSize="28px" fontWeight="700" color="black" mb="27px" textAlign="left">
            {t(title)}
        </Text>
    );
};

export default SectionTitle;
