import React, { memo } from "react";
import { Box, Image, Text, Button, VStack } from "@chakra-ui/react";
import ImgPlay from '../../../assets/imgs/product-play.png';

interface ProductCardProps {
    title: string;
    plan?: string | null | boolean;
    onClick: () => void;
    textButton: string;
    description?: string;
    linkProductActive?: (title: string) => void | undefined;
    href?: string;
}

const ProductCard: React.FC<ProductCardProps> = memo(({ title, plan, onClick, textButton, description, linkProductActive, href }) => {
    const upgrade = textButton === "Upgrade" || textButton === "Actualizar";
    const comingSoon = textButton === "Próximamente" || textButton === "Coming soon";
    return (
        <Box
            maxW="330px"
            minW="330px"
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            boxShadow="md"
            bg="white"
            p={4}
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            _hover={
                !comingSoon
                    ? { cursor: "pointer", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.3)" }
                    : {}
            }
            onClick={() => {
                if (!comingSoon && linkProductActive) {
                    linkProductActive(title);
                }
            }}
        >
            <Image src={ImgPlay} alt={title} borderRadius="md" mb={4} />
            <VStack align="start" spacing={2}>
                <Text fontSize="xl" fontWeight="bold" color="black" textTransform="capitalize">
                    {title}
                </Text>
                <Text fontSize="md" color="gray.600">
                    {plan}
                </Text>
                <Text fontSize="sm" color="gray.500" mb="35px" textAlign="left">
                    {description}
                </Text>
            </VStack>
            <Button
                mt={4}
                bg={upgrade ? "green.100" : "blue.700"}
                color="white.50"
                width="90%"
                as={href ? "a" : undefined}
                href={href}
                onClick={(e) => {
                    e.stopPropagation();
                    if (!comingSoon) {
                        onClick();
                    }
                }}
                _hover={
                    comingSoon
                        ? {}
                        : { backgroundColor: upgrade ? "green.300" : "blue.800" }
                }
                disabled={comingSoon}
            >
                {textButton}
            </Button>
        </Box>

    );
});

export default ProductCard;
