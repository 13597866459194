import React from "react";

const Logo: React.FC<{
  width?: number;
  height?: number;
  color?: string;
}> = ({ width = 164, height = 42 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 164 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="canaia-1 1" clipPath="url(#clip0_112_1398)">
                <g id="Capa_1-2">
                    <path id="Vector" d="M51.5998 14.6792C53.2238 14.6792 54.7537 14.9873 56.1844 15.6139C57.6151 16.2352 58.8735 17.0759 59.9492 18.1411C61.0248 19.2063 61.876 20.4647 62.4973 21.9163C63.1187 23.3679 63.4268 24.9031 63.4268 26.5322V38.0302H58.8579V35.8789C57.8501 36.6413 56.7431 37.2417 55.5265 37.6803C54.3151 38.119 53.0097 38.3383 51.6207 38.3383C49.9916 38.3383 48.4617 38.0302 47.0257 37.4088C45.5898 36.7875 44.3418 35.9416 43.2766 34.8763C42.2114 33.8111 41.3707 32.5632 40.7494 31.1272C40.128 29.6913 39.8147 28.1614 39.8147 26.5322C39.8147 24.9031 40.1228 23.3679 40.7441 21.9163C41.3655 20.4647 42.2062 19.2063 43.2714 18.1411C44.3366 17.0759 45.5793 16.2352 47.0153 15.6139C48.446 14.9925 49.9759 14.6792 51.5998 14.6792ZM51.626 33.7641C52.6337 33.7641 53.5736 33.5709 54.4456 33.1846C55.3176 32.7982 56.08 32.276 56.7327 31.6233C57.3854 30.9706 57.9023 30.203 58.2835 29.3258C58.6699 28.4486 58.8631 27.5191 58.8631 26.5322C58.8631 25.5454 58.6699 24.5741 58.2835 23.6969C57.8971 22.8197 57.3802 22.0521 56.7327 21.3942C56.08 20.7415 55.3176 20.2193 54.4456 19.8329C53.5736 19.4465 52.6337 19.2533 51.626 19.2533C50.6182 19.2533 49.6783 19.4465 48.8063 19.8329C47.9343 20.2193 47.1719 20.7415 46.5192 21.3942C45.8665 22.0469 45.3496 22.8145 44.9684 23.6969C44.582 24.5741 44.3888 25.5192 44.3888 26.5322C44.3888 27.5452 44.582 28.4433 44.9684 29.3258C45.3548 30.203 45.8718 30.9706 46.5192 31.6233C47.1719 32.276 47.9343 32.7982 48.8063 33.1846C49.6783 33.5709 50.6182 33.7641 51.626 33.7641Z" fill="#1D1D1B"/>
                    <path id="Vector_2" d="M86.3758 16.1883C87.7804 17.1334 88.8978 18.3552 89.7176 19.8486C90.5374 21.342 90.9499 22.9764 90.9499 24.7517V38.1086H86.381V24.7517C86.381 23.9841 86.2296 23.2479 85.9372 22.5534C85.6396 21.8589 85.2323 21.2532 84.7101 20.7363C84.188 20.2194 83.5875 19.8121 82.9034 19.5144C82.2194 19.2168 81.4936 19.0706 80.7208 19.0706C79.948 19.0706 79.2118 19.2168 78.5121 19.5144C77.8124 19.8121 77.2119 20.2194 76.7054 20.7363C76.1989 21.2532 75.7968 21.8589 75.5044 22.5534C75.2068 23.2479 75.0606 23.9789 75.0606 24.7517V38.1086H70.4917V14.5017H75.0606L75.0972 16.1883C75.8961 15.6557 76.7785 15.2432 77.7393 14.9455C78.7001 14.6479 79.7026 14.5017 80.7365 14.5017C81.7704 14.5017 82.7729 14.6479 83.7337 14.9455C84.6944 15.2432 85.5769 15.6557 86.3758 16.1883Z" fill="#1D1D1B"/>
                    <path id="Vector_3" d="M109.168 14.6792C110.792 14.6792 112.322 14.9873 113.753 15.6139C115.183 16.2352 116.442 17.0759 117.517 18.1411C118.593 19.2063 119.444 20.4647 120.065 21.9163C120.687 23.3679 120.995 24.9031 120.995 26.5322V38.0302H116.426V35.8789C115.418 36.6413 114.311 37.2417 113.095 37.6803C111.883 38.119 110.578 38.3383 109.189 38.3383C107.56 38.3383 106.03 38.0302 104.594 37.4088C103.158 36.7875 101.91 35.9416 100.845 34.8763C99.7795 33.8111 98.9388 32.5632 98.3123 31.1272C97.6909 29.6913 97.3828 28.1614 97.3828 26.5322C97.3828 24.9031 97.6909 23.3679 98.3123 21.9163C98.9336 20.4647 99.7743 19.2063 100.84 18.1411C101.905 17.0759 103.153 16.2352 104.583 15.6139C106.014 14.9925 107.544 14.6792 109.168 14.6792ZM109.189 33.7641C110.197 33.7641 111.131 33.5709 112.009 33.1846C112.881 32.7982 113.643 32.276 114.296 31.6233C114.948 30.9706 115.465 30.203 115.846 29.3258C116.233 28.4486 116.426 27.5191 116.426 26.5322C116.426 25.5454 116.233 24.5741 115.846 23.6969C115.46 22.8197 114.943 22.0521 114.296 21.3942C113.643 20.7415 112.881 20.2193 112.009 19.8329C111.137 19.4465 110.197 19.2533 109.189 19.2533C108.181 19.2533 107.241 19.4465 106.369 19.8329C105.497 20.2193 104.735 20.7415 104.082 21.3942C103.429 22.0469 102.912 22.8145 102.531 23.6969C102.145 24.5741 101.952 25.5192 101.952 26.5322C101.952 27.5452 102.145 28.4433 102.531 29.3258C102.918 30.203 103.435 30.9706 104.082 31.6233C104.735 32.276 105.497 32.7982 106.369 33.1846C107.241 33.5709 108.181 33.7641 109.189 33.7641Z" fill="#3A7684"/>
                    <path id="Vector_4" d="M133.333 14.6323V38.0668H128.765V14.6323H133.333ZM134.132 9.43155C134.132 11.1338 132.749 12.5175 131.046 12.5175C129.344 12.5175 127.96 11.1338 127.96 9.43155C127.96 7.7293 129.344 6.34558 131.046 6.34558C132.749 6.34558 134.132 7.7293 134.132 9.43155Z" fill="#3A7684"/>
                    <path id="Vector_5" d="M152.173 14.6792C153.797 14.6792 155.327 14.9873 156.758 15.6139C158.188 16.2352 159.447 17.0759 160.522 18.1411C161.598 19.2063 162.449 20.4647 163.071 21.9163C163.692 23.3679 164 24.9031 164 26.5322V38.0302H159.431V35.8789C158.423 36.6413 157.316 37.2417 156.1 37.6803C154.888 38.119 153.583 38.3383 152.194 38.3383C150.565 38.3383 149.035 38.0302 147.599 37.4088C146.163 36.7875 144.915 35.9416 143.85 34.8763C142.785 33.8111 141.939 32.5632 141.317 31.1272C140.696 29.6913 140.388 28.1614 140.388 26.5322C140.388 24.9031 140.696 23.3679 141.317 21.9163C141.939 20.4647 142.779 19.2063 143.845 18.1411C144.91 17.0759 146.158 16.2352 147.589 15.6139C149.019 14.9925 150.549 14.6792 152.173 14.6792ZM152.194 33.7641C153.202 33.7641 154.142 33.5709 155.014 33.1846C155.886 32.7982 156.648 32.276 157.301 31.6233C157.953 30.9706 158.47 30.203 158.852 29.3258C159.238 28.4486 159.431 27.5191 159.431 26.5322C159.431 25.5454 159.238 24.5741 158.852 23.6969C158.465 22.8197 157.948 22.0521 157.301 21.3942C156.648 20.7415 155.886 20.2193 155.014 19.8329C154.142 19.4465 153.202 19.2533 152.194 19.2533C151.186 19.2533 150.246 19.4465 149.374 19.8329C148.502 20.2193 147.74 20.7415 147.087 21.3942C146.435 22.0469 145.918 22.8145 145.531 23.6969C145.145 24.5741 144.957 25.5192 144.957 26.5322C144.957 27.5452 145.15 28.4433 145.531 29.3258C145.918 30.203 146.435 30.9706 147.087 31.6233C147.74 32.276 148.502 32.7982 149.374 33.1846C150.246 33.5709 151.186 33.7641 152.194 33.7641Z" fill="#1D1D1B"/>
                    <path id="Vector_6" d="M16.0983 22.6891C17.546 22.6891 18.7195 21.5156 18.7195 20.0679C18.7195 18.6202 17.546 17.4467 16.0983 17.4467C14.6506 17.4467 13.4771 18.6202 13.4771 20.0679C13.4771 21.5156 14.6506 22.6891 16.0983 22.6891Z" fill="#3A7684"/>
                    <path id="Vector_7" d="M23.0846 22.6891C24.5323 22.6891 25.7059 21.5156 25.7059 20.0679C25.7059 18.6202 24.5323 17.4467 23.0846 17.4467C21.6369 17.4467 20.4634 18.6202 20.4634 20.0679C20.4634 21.5156 21.6369 22.6891 23.0846 22.6891Z" fill="#3A7684"/>
                    <path id="Vector_8" d="M30.4523 22.6891C31.9 22.6891 33.0735 21.5156 33.0735 20.0679C33.0735 18.6202 31.9 17.4467 30.4523 17.4467C29.0046 17.4467 27.8311 18.6202 27.8311 20.0679C27.8311 21.5156 29.0046 22.6891 30.4523 22.6891Z" fill="#3A7684"/>
                    <path id="Vector_9" d="M32.5723 29.7488C31.4549 28.9133 29.8623 28.9916 28.9016 30.0046C27.993 30.9602 26.9487 31.7695 25.7686 32.4431C23.8784 33.5187 21.8054 34.0566 19.5444 34.0566C17.7064 34.0566 15.9885 33.6858 14.3803 32.9444C12.772 32.2029 11.3674 31.1951 10.1717 29.9263C8.97071 28.6574 8.02038 27.1745 7.31024 25.4775C6.60532 23.7805 6.25026 21.9633 6.25026 20.0209C6.25026 18.0785 6.60532 16.2614 7.31024 14.5643C8.01516 12.8725 8.97071 11.3896 10.1717 10.1155C11.3726 8.84669 12.7773 7.83892 14.3803 7.09745C15.9885 6.35598 17.7064 5.98525 19.5444 5.98525C21.8054 5.98525 23.8784 6.52308 25.7686 7.59873C26.9487 8.27231 27.993 9.08166 28.9016 10.0372C29.8676 11.045 31.4549 11.1285 32.5723 10.2931C33.9717 9.24875 34.0918 7.19666 32.8334 5.98525C31.5176 4.7164 30.0294 3.64075 28.3585 2.75308C25.6955 1.34847 22.7557 0.643555 19.5444 0.643555C16.8971 0.643555 14.4064 1.15527 12.0776 2.17871C9.74873 3.20214 7.71753 4.58586 5.98918 6.3351C4.26083 8.08433 2.89277 10.1312 1.885 12.4757C0.877229 14.8254 0.375955 17.337 0.375955 20.0209C0.375955 22.7048 0.877229 25.2216 1.885 27.5661C2.32883 28.6 2.85099 29.566 3.43581 30.485L0 41.3564L11.7643 37.7169C11.8687 37.7639 11.9679 37.8213 12.0776 37.8683C14.4064 38.8918 16.8971 39.4035 19.5444 39.4035C22.7557 39.4035 25.6955 38.6986 28.3637 37.2835C30.0294 36.4011 31.5228 35.3254 32.8386 34.0513C34.097 32.8399 33.977 30.7879 32.5776 29.7435L32.5723 29.7488Z" fill="#3A7684"/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_112_1398">
                    <rect width="164" height="40.7128" fill="white" transform="translate(0 0.643555)"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default Logo;
