import React from "react";
import { Box } from "@chakra-ui/react";

interface CenteredContainerProps {
    children: React.ReactNode;
}

const CenteredContainer: React.FC<CenteredContainerProps> = ({ children }) => {
    return (
        <Box
            minHeight="100vh"
            display="flex"
            bg="grey.50"
            paddingX="80px"
            paddingTop="39px"
            paddingBottom="0"
            alignItems="center"
            justifyContent="center"
        >
            {children}
        </Box>
    );
};

export default CenteredContainer;
