import createApiClient from '../ApiClients';

interface UpdateUserData {
    name: string;
    language: string;
}
interface UpdatePasswordData {
    old_password: string;
    new_password: string;
}

export const updateUser = async (token: string, data: UpdateUserData, signOut: () => Promise<void>, showToast: (message: string) => void) => {
    try {
        const apiClient = createApiClient(token, signOut, showToast);
        const response = await apiClient.patch('', data);
        return response.data;
    } catch (error) {
        console.error("Error updating user:", error);
        throw error; 
    }
};


export const updatePassword = async (token: string, data: UpdatePasswordData, signOut: () => Promise<void>,  showToast: (message: string) => void) => {
    try {
        const apiClient = createApiClient(token, signOut, showToast);
        const response = await apiClient.patch('/password', data);
        return response.data;
    } catch (error) {
        console.error("Error updating password:", error);
        throw error;
    }
};
