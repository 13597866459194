import { ToastId, UseToastOptions } from "@chakra-ui/react";
import { TFunction } from "i18next";

type ToastType =  "success" | "successUser" | "successPassword" | "error" | "requestError";

interface ToastMessages {
    title: string;
    description: string;
    status: UseToastOptions["status"];
}

export const showToast = (
    toast: (options?: UseToastOptions | undefined) => ToastId,
    t: TFunction,
    type: ToastType,
    textColor: string = "#ffffff"
) => {
    const toastOptions: Record<ToastType, ToastMessages> = {
        success: {
            title: t("success"),
            description: t("allChangesWereSavedSuccessfully"),
            status: "success",
        },
        successUser: {
            title: t("success"),
            description: t("successUser"),
            status: "success",
        },
        successPassword: {
            title: t("success"),
            description: t("successPassword"),
            status: "success",
        },
        error: {
            title: "Error",
            description: t("noChangesSavedDueError"),
            status: "error",
        },
        requestError: {
            title: "Error",
            description: t("thereWasIssueWithRequest"),
            status: "error",
        },
    };

    toast({
        title: toastOptions[type].title,
        description: toastOptions[type].description,
        status: toastOptions[type].status,
        duration: 3000,
        isClosable: true,
        containerStyle: {
            color: textColor, // Color del texto
        },
    });
};
