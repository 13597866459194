import React, { useEffect, useState } from "react";
import { Box, Flex, SimpleGrid, Spinner } from "@chakra-ui/react";
import ProductCard from "../../ui/Components/CardsProducts";
import Title from '../../ui/Components/Title';
import { fetchPlans } from "../../api/Calls/products";
import { useLogto } from "@logto/react";
import { useRedirectStripe }from '../../hooks/useRedirectStripe'
import { moreProducts } from "./mocks";
import { useTranslation } from "react-i18next";
import { filterMoreProducts, linkProductActive } from "./utils";
import { Product, PlanData } from "./interfaces";
import useToastError from '../../hooks/useToastError/index';

const ProductList: React.FC = () => {
    const { t } = useTranslation();
    const [planData, setPlanData] = useState<PlanData[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { getAccessToken, signOut } = useLogto()
    const redirectStripe = useRedirectStripe();
    const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
    const showToastError = useToastError();

    useEffect(() => {
        const fetchDataPlan = async (token: string) => {
            try {
                const data = await fetchPlans(token,signOut, showToastError);
                setPlanData(data)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            } catch (error) {   
                setError(t("errorAPI"));
            } finally {
                setIsLoading(false)
            }
        };
        getAccessToken(import.meta.env.VITE_LOGTO_API_ID).then(token => {
            return fetchDataPlan(token ? token : "");
        })
    }, [getAccessToken]);

    useEffect(() => {
     
        const result = filterMoreProducts(moreProducts, planData);
        setFilteredProducts(result);
  
       
    }, [planData]);
    
    if (error) return <p>{error}</p>;


    return (
        <Box maxW="1350px" mx="auto"> 
            <Title title={t("welcomeProducts")}/>
           
            {!isLoading && planData
                ?   <Box mb="90px" >
                    <SimpleGrid columns={{ base: 1, sm: 2, md: 3}} spacing="25px" minChildWidth="330px">
                        {planData.map((data, index) => (
                            data.plan  &&
                                <ProductCard
                                    key={index}
                                    title={data.name}
                                    plan={data.plan}
                                    onClick={() => redirectStripe(data.name)}
                                    textButton={t("upgrade")}
                                    description={t(data.description)}
                                    linkProductActive={linkProductActive}
                                />
                        ))}
                    </SimpleGrid>
                </Box>
                : <Flex minHeight="500px" minW="200px" alignItems="center" justifyContent="center">
                    <Spinner color="teal.500" size="lg" />
                </Flex>

            }
            <Box mb="90px">
                <Title title="exploreMoreProducts"  />
                {filteredProducts.length > 0 && !isLoading
                    ? <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing="25px"  minChildWidth="330px">
                        {filteredProducts.map((product, index) => (
                            <ProductCard
                                key={index}
                                title={product.name}
                                plan={product.plan}
                                href={product.onSubscribe}
                                onClick={() => window.location.href = product.onSubscribe }
                                textButton={product.comingSoon ?  t("comingSoon") :  t("subscribe")}
                                description={t(product.description)}
                                linkProductActive={linkProductActive}
                            />
                        ))}
                    </SimpleGrid> 
                    : <Flex minHeight="500px" minW="200px" alignItems="center" justifyContent="center">
                        <Spinner color="teal.500" size="lg" />
                    </Flex>
                }
               
            </Box>
        </Box>

    
    );
};

export default ProductList;
