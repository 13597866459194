import React from "react";
import { Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface SectionDescriptionProps {
    description: string;
}

const SectionDescription: React.FC<SectionDescriptionProps> = ({ description }) => {
    const { t } = useTranslation();
    return (
        <Text fontSize="20px" fontWeight="400" color="black" mb="45px" textAlign="left">
            {t(description)}
        </Text>
    );
};

export default SectionDescription;
