import React from "react";

const Logo: React.FC<{
  width?: number;
  height?: number;
  color?: string;
}> = ({ width = 24, height = 42 , color="#FFF" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="arrow--right 1" clipPath="url(#clip0_85_425)">
                <path id="Vector" d="M13.5 4.5L12.4275 5.54475L18.1125 11.25H3V12.75H18.1125L12.4275 18.4298L13.5 19.5L21 12L13.5 4.5Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_85_425">
                    <rect width="24" height="24" fill={color}/>
                </clipPath>
            </defs>
        </svg>
        
    );
};

export default Logo;
