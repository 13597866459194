import React from "react";
import { Box, Text, Flex, Heading } from "@chakra-ui/react";
import ArrowRIght from "../../../icons/arrowRight";
import { useTranslation } from "react-i18next";

interface SubscriptionInactiveCardProps {
    title: string;
    onManageSubscription: () => void;
}

const SubscriptionInactiveCard: React.FC<SubscriptionInactiveCardProps> = ({
    title,
    onManageSubscription,
}) => {
    const { t } = useTranslation();
    return (
        <Box
            borderRadius="md"
            boxShadow="sm"
            p="35px"
            bgGradient="linear(to-r, blue.50, blue.100)"
            color="white"
            minW="564px"
            border="1px solid"
            borderColor="gray.200"
        >
            <Flex direction="column" alignItems="flex-start" justify="space-between" >
                <Flex direction="column" alignItems="flex-start" mb={4} >
                    <Heading as="h3" fontSize="24px" fontWeight="bold" textTransform="capitalize">
                        {title}
                    </Heading>
                    <Text fontSize="18px" mt="14px" mb="25px">
                        {t("youNotHaveActiveSubscription")}
                    </Text>
                </Flex>
                
               
            </Flex>
            <Flex maxW="96%" justify="flex-end" align="center" mt="30px" _hover={{ cursor:"pointer", transform: "scale(1.1)" }} transition="transform 0.2s ease">
                <Flex color="white.50" fontWeight="600" fontSize="19px" onClick={onManageSubscription}>
                    {t("manageSubscription")}
                </Flex>
                <Flex ml="8px" >
                    <ArrowRIght />
                </Flex>
            </Flex>
        </Box>
    );
};

export default SubscriptionInactiveCard;
