import { Outlet } from "react-router-dom";
import {useLogto} from "@logto/react"
import { useEffect, useState } from "react";

const ProtectedRoute = () => {
    const { signIn, isAuthenticated} = useLogto()
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isAuthenticated !== undefined) {
            setIsLoading(false);
        }
    }, [isAuthenticated]);

    if (isLoading) return <div>Loading...</div>;

    if (!isAuthenticated) {
        signIn(window.location.origin + '/sign-in-callback');
        return null;
    } 

    return <Outlet />;
};

export default ProtectedRoute;
