import { Box, Progress } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";

const gradient = keyframes`
  0% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
`;

const progressAnimation = `${gradient} 2s infinite alternate`;

const AnimatedProgress = ({ progress }: { progress: number }) => {
    return (
        <Box width="100%">
            <Progress
                value={progress}
                size="sm"
                borderRadius="md"
                height="10px"
                sx={{
                    "& > div": {
                        background: "linear-gradient(90deg, #38ADC7, #0C56C0)",
                        backgroundSize: "200% 200%",
                        animation: progressAnimation,
                    },
                }}
            />
        </Box>
    );
};

export default AnimatedProgress;
