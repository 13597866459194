import { Route, Routes } from "react-router-dom";
import SignInCallback from "../pages/SignInCallback.tsx";
import Products from '../pages/Products';
import Layout from "../ui/Layout";
import ProtectedRoute from "./ProtectedRoute.tsx";
import PlanAndBilling from "../pages/PlanAndBilling/index.tsx";
import Settings from '../pages/Settings';

function AppRoutes() {

    return (
        <>
            <Routes>
                <Route path="/sign-in-callback" element={<SignInCallback />} />
                <Route element={<ProtectedRoute />}> 
                    <Route element={<Layout />}>
                        <Route path="/" Component={Products} />
                        <Route path="/plan-and-billing" Component={PlanAndBilling} />
                        <Route path="/settings" Component={Settings} />
                    </Route>
                </Route>
            </Routes>
        </>
    );
}

export default AppRoutes;
