import React from "react";
import { VStack, Box} from "@chakra-ui/react";
import { useToken } from "@chakra-ui/react";
import { Wallet, Settings, Catalog } from "../../../../icons";
import { useLocation, Link } from "react-router-dom";

const LittleMenu: React.FC = () => {
    const location = useLocation();
    const isActive = (path: string) => location.pathname === path;

    const [activeColor, inactiveColor] = useToken("colors", ["blue.50", "grey.100"]);
    
    
    return (
        <VStack align="start" spacing={4} p={4} pt="100px">
            <Link to="/">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    pt="10px"
                    pb="10px"
                    p="8px"
                    borderRadius="md"
                    width="100%"
                    bg={isActive("/") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Catalog color={ isActive("/") ? activeColor : inactiveColor} width={30} height={30}/>
                  
                    
                </Box>
            </Link>
          
            <Link to="/plan-and-billing">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    pt="10px"
                    pb="10px"
                    p="8px"
                    borderRadius="md"
                    width="100%"
                    bg={isActive("/plan-and-billing") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Wallet color={ isActive("/plan-and-billing") ? activeColor : inactiveColor} width={30} height={30}/>
               
              
                </Box>
            </Link>
            <Link to="/settings">
                <Box 
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    pt="10px"
                    pb="10px"
                    p="8px"
                    borderRadius="md"
                    width="100%"
                    bg={isActive("/settings") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500" , cursor: "pointer"}}
                >
                    <Settings color={ isActive("/settings") ? activeColor : inactiveColor} width={30} height={30} />
        
                </Box>
            </Link>
        </VStack>
    );
};

export default LittleMenu;
