import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            welcome: "Welcome",
            profile: "Profile",
            settings: "Settings",
            activeSubscriptions: "Active subscriptions",
            planAndBilling: "Plans & Billing",
            welcomeProducts: "Welcome to your products",
            exploreMoreProducts: "Explore more products",
            comingSoon: "Coming soon",
            subscribe: "Subscribe",
            upgrade: "Upgrade",
            success: "Success",
            successUser: "User changed successfully",
            successPassword: "Password changed successfully.",
            allChangesWereSavedSuccessfully: "All changes were saved successfully.",
            partialSuccess: "Partial success",
            yourProfileUpdatedButIssueUpdatingPassword: "Your profile was updated, but there was an issue updating your password.",
            yourPasswordUpdatedButIssueUpdatingProfile:"Your password was updated, but there was an issue updating your profile.",
            noChangesSavedDueError: "No changes were saved due to an error.",
            thereWasIssueWithRequest: "There was an issue with your request.",
            personalInformation: "Personal information",
            youCanChangeAccountSettingsHere: "You can change your account here.",
            errorAuthenticating: "There was an error with your authentication.",
            manageSubscription: "Manage subscription",
            of: "of",
            credits: "credits",
            youNotHaveActiveSubscription: "You don’t have any active subscription",
            name: "Name",
            email: "Email",
            password: "Password",
            oldPassword: "Actual password",
            newPassword: "New password",
            language: "Language",
            spanish: "Spanish",
            english: "English",
            save: "Save",
            cancel: "Cancel",
            catalog: "Catalog",
            assistDescription: "Your conversational assistant that will manage all your communication channels.",
            playDescription: "It is a virtual innovation laboratory designed to explore and experiment with the latest artificial intelligence technologies.",
            talentDescription: "An innovative training tool powered by artificial intelligence, designed to transform learning into an exciting and fun experience",
            elevateDescription: "The most powerful knowledge tool on the market.",
            inactiveSubscriptions: "Inactive subscriptions",
            logOut: "Log out",
            errorAPI: "There was an error in the application."
        }
    },
    es: {
        translation: {
            welcome: "Bienvenido",
            profile: "Perfil",
            settings: "Configuración",
            activeSubscriptions: "Suscripción activa",
            planAndBilling: "Planes y facturación",
            welcomeProducts: "Bienvenido a tus productos",
            exploreMoreProducts: "Explorar más productos",
            comingSoon: "Próximamente",
            subscribe: "Suscríbete",
            upgrade: "Actualizar",
            success: "Guardado",
            successUser: "Usuario cambiado correctamente.",
            successPassword: "Contraseña cambiada correctamente.",
            allChangesWereSavedSuccessfully: "Todos los cambios fueron guardados correctamente.",
            partialSuccess: "Guardado parcialmente",
            yourProfileUpdatedButIssueUpdatingPassword: "Tu perfil se actualizó, pero hubo un problema con la contraseña.",
            yourPasswordUpdatedButIssueUpdatingProfile:"Tu contraseña fue actualizada, pero hubo un problema con tu perfil.",
            noChangesSavedDueError: "No se guardaron los cambios debido a un error.",
            thereWasIssueWithRequest: "Hubo un error en la petición.",
            personalInformation: "Información personal",
            youCanChangeAccountSettingsHere: "Puedes hacer cambios en tu cuenta aquí.",
            errorAuthenticating: "Hubo un error al autentificar..",
            manageSubscription : "Administrar suscripción",
            of: "de",
            credits: "creditos",
            youNotHaveActiveSubscription: "No tienes ninguna suscripción activa",
            name: "Nombre",
            email: "Email",
            password: "Contraseña",
            oldPassword: "Contraseña actual",
            newPassword: "Contraseña nueva",
            language: "Idioma",
            spanish: "Español",
            english: "Inglés",
            save: "Guardar",
            cancel: "Cancelar",
            catalog: "Catalogo",
            assistDescription: "Tu asistente conversacional que gestionará todos tus canales de comunicación.",
            playDescription: "Es un laboratorio virtual de innovación diseñado para explorar y experimentar con las últimas tecnologías de inteligencia artificial",
            talentDescription: "Una herramienta de formación innovadora impulsada por inteligencia artificial, diseñada para transformar el aprendizaje en una experiencia emocionante y divertida",
            elevateDescription: "La herramienta de conocimiento más potente del mercado.",
            inactiveSubscriptions: "Suscripciones inactivas",
            logOut: "Cerrar sesión",
            errorAPI: "Hubo un error en la aplicación."
        }
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: "es", // Initial language
    fallbackLng: "en", // Language in case there is no translation
    interpolation: {
        escapeValue: false, // react-i18next takes care of security
    }
});



export default i18n;
