import i18n from '../../translator/i18n';
import createApiClient from '../ApiClients';

type ApiResponse = {
    status: number;
    data: { name: string, plan: string | null | boolean };
};

export const fetchPlans = async (token: string, signOut: () => Promise<void>, showToast: (message: string) => void) => {
    const apiClient = createApiClient(token, signOut, showToast);

    try {
        const responses: ApiResponse[] = await Promise.all([
            apiClient.get(`/subscriptions/play`).catch(error => {
                if (error.response?.status === 404) {
                    return { data: { name: 'Play', plan: false }, status: 404 };
                }
                throw error;
            }),
            apiClient.get(`/subscriptions/assist`).catch(error => {
                if (error.response?.status === 404) {
                    return { data: { name: 'Assist', plan: false }, status: 404 };
                }
                throw error;
            })
        ]);

        const playData = {
            ...responses[0].data,
            name: 'Play',
            description: i18n.t("playDescription")
        };

        const assistData = {
            ...responses[1].data,
            name: 'Assist',
            description: i18n.t("assistDescription")
        };

        return [playData, assistData];
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};
