import ImgRobot from '../../assets/imgs/product-robot.png';

export const moreProducts = [
    {
        name: "Assist",
        plan: "",
        description: "assistDescription",
        imageUrl: ImgRobot,
        onSubscribe: import.meta.env.VITE_SUBSCRIPTION_ASSIST_URL
    },
    {
        name: "Elevate", // "Coming soon"
        plan: "",
        description:"elevateDescription",
        imageUrl: ImgRobot,
        onSubscribe: "",
        comingSoon: true
    },
    {
        name: "Talent", // "Coming soon"
        plan: "",
        description:"talentDescription",
        imageUrl: ImgRobot,
        onSubscribe: "",
        comingSoon: true
    },
    {
        name: "Play",
        plan: "",
        description:"playDescription",
        imageUrl: ImgRobot,
        onSubscribe: import.meta.env.VITE_SUBSCRIPTION_PLAY_URL
    },
  
];