import createApiClient from '../ApiClients';

export const fetchManagment = async (token: string, product: string, signOut: () => Promise<void>, showToast: (message: string) => void) => {
    try {
        const apiClient = createApiClient(token, signOut, showToast);  // Crear cliente con el interceptor de 401
        const response = await apiClient.get(`/subscriptions/${product}/portal`);
        return response.data;
    } catch (error) {
        console.error("Error fetching management data:", error);
        throw error;
    }
};
