import React from "react";
import { Box, Text, Flex, Heading } from "@chakra-ui/react";
import AnimatedProgress from "./animateProgress";
import ArrowRIght from "../../../icons/arrowRight";
import { useToken } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { SubscriptionCardProps } from "./interfaces";

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
    title,
    plan,
    price,
    creditsUsed,
    totalCredits,
    onManageSubscription,
}) => {
    const [iconColor] = useToken("colors", ["blue.700"]);
    const progress = (creditsUsed / totalCredits) * 100;
    const { t } = useTranslation();

    return (
        <Box
            borderRadius="md"
            boxShadow="sm"
            py="25px"
            px="30px"
            bg="gray.50"
            w={{ base: "100%", md: "564px" }}
            border="1px solid"
            borderColor="gray.200"
          
        >
            <Flex justify="space-between" align="center">
                <Flex flexDir="column" alignItems="flex-start">
                    <Heading as="h3" fontSize="24px" fontWeight="bold" textTransform="capitalize">
                        {title}
                    </Heading>
                    <Text mt="10px" fontSize="18px" color="gray.500"> {plan}</Text>
                </Flex>
                {price && <Heading fontSize="28px" fontWeight="bold" color="black.300">
                    {price} <Text as="span" fontSize="md" color="gray.600">/month</Text>
                </Heading>}
            
            </Flex>

            <Box mt="20px" mb="33px" >
                {creditsUsed && totalCredits && <>
                    <Box mb={4} display="flex" flexDirection="column" alignItems="flex-start"  width="100%">
                        <Text mt={4} color="gray.600" fontSize="sm">
                            {creditsUsed.toLocaleString()} {t('of')} {totalCredits.toLocaleString()} {t('credits')}
                        </Text>
                    </Box>
                    <AnimatedProgress progress={progress}
                    />
                </>}
               
            </Box>
          
          
            

            <Flex maxW="96%" justify="flex-end" align="center" mt="30px" _hover={{ cursor:"pointer", transform: "scale(1.1)" }} transition="transform 0.2s ease">
                <Flex color="blue.700" fontWeight="600" fontSize="19px" onClick={onManageSubscription}>
                    {t("manageSubscription")}
                </Flex>
                <Flex ml="8px">
                    <ArrowRIght color={iconColor} />
                </Flex>
             
            </Flex>
        </Box>
    );
};

export default SubscriptionCard;
