import {create} from 'zustand';
import { persist } from 'zustand/middleware';

type State = {
    isLoading: boolean
    userName: string
    accessToken: string
    assistantId: string
    infoUser: {
        email: string
        }
    language: string;
}

type Actions = {
    setIsLoading: () => void,
    setUserName: (userName: string | undefined) => void
    setInfoUser: (infoUser: {
        email: string
        }) => void
    setAccessToken: (accessToken: State['accessToken']) => void,
    setPageId: (pageId: string) => void
    setLanguage: (language: string) => void
}

export const authStore = create<State & Actions>()(
    persist(
        (set) => ({
            isLoading: false,
            setIsLoading: () => set((state) => ({ isLoading: !state.isLoading })),

            userName: '',
            setUserName: (userName) => set(() => ({ userName: userName || '' })),

            infoUser: { email: '' },
            setInfoUser: (infoUser) => set(() => ({ infoUser: infoUser })),

            accessToken: '',
            setAccessToken: (token) => set(() => ({ accessToken: token })),

            assistantId: '',
            setPageId: (pageId) => set(() => ({ assistantId: pageId })),

            language: '',
            setLanguage: (lang) => set(() => ({ language: lang })),
        }),
        {
            name: 'auth-store',
            partialize: (state) => ({
                language: state.language,
                userName: state.userName,
            }),
        }
    )
);

