import { useLogto } from "@logto/react";
import {fetchManagment} from '../api/Calls/managment';

import { useCallback } from "react";
import useToastError from './useToastError'

export const useRedirectStripe = () => {
    const { getAccessToken, signOut } = useLogto();
    const showToastError = useToastError();


    const fetchAndRedirect = useCallback(async (product: string) => {
        try {
            const token = await getAccessToken(import.meta.env.VITE_LOGTO_API_ID);
            if (!token) {
                console.error("Token is undefined");
                return;
            }
            const response = await fetchManagment(token, product.toLowerCase(), signOut, showToastError);
            const redirectUrl = response.url;
            
            if (redirectUrl) {
                window.location.href = redirectUrl;
            } else {
                console.error("No redirect URL found in response");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }, [getAccessToken]);

    return fetchAndRedirect;
};
