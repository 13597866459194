import React, { useEffect, useState } from "react";
import { VStack, Box, Text } from "@chakra-ui/react";
import { useToken } from "@chakra-ui/react";
import { Wallet, Settings, Catalog } from "../../../icons";
import { useLocation, Link } from "react-router-dom";
import LittleMenu from "./littleMenu.tsx";
import {applyRevealEffect} from './utils.tsx';
import { useTranslation } from "react-i18next";

interface SidebarMenuProps {
    isMenuOpen: boolean;
}

const SidebarMenu: React.FC<SidebarMenuProps> = ({isMenuOpen}) => {
    const location = useLocation();
    const isActive = (path: string) => location.pathname === path;
    const { t } = useTranslation();

    const [activeColor, inactiveColor] = useToken("colors", ["blue.50", "grey.100"]);
    const [visibleTexts, setVisibleTexts] = useState({ catalog: "", plan: "", settings: "" });


    useEffect(() => {
        if (isMenuOpen) {
            applyRevealEffect(t("catalog"), (visibleText) =>
                setVisibleTexts((prev) => ({ ...prev, catalog: visibleText }))
            );
            applyRevealEffect(t("planAndBilling"), (visibleText) =>
                setVisibleTexts((prev) => ({ ...prev, plan: visibleText }))
            );
            applyRevealEffect(t("settings"), (visibleText) =>
                setVisibleTexts((prev) => ({ ...prev, settings: visibleText }))
            );
        } else {
            setVisibleTexts({ catalog: "", plan: "", settings: "" });
        }
    }, [isMenuOpen, t]);
    
    if(!isMenuOpen) return <LittleMenu />
    return (
        <VStack align="start" spacing={4} p={4} pt="110px">
            <Link to="/" style={{display: "block", minWidth: "100%"}} >
                <Box
                    display="flex"
                    alignItems="start"
                    pt="10px"
                    pb="10px"
                    px="15px"
                    borderRadius="md"
                    bg={isActive("/") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Catalog color={ isActive("/") ? activeColor : inactiveColor} />
                    <Text
                        fontWeight="bold"
                        color="grey.100"
                        ml="11px"
                        fontSize="19px"
                        opacity={0.9}
                        transition="opacity 0.3s"
                    >
                        {visibleTexts.catalog}
                    </Text>
                    
                </Box>
            </Link>
          
            <Link to="/plan-and-billing" style={{display: "block", minWidth: "100%"}}>
                <Box
                    display="flex"
                    alignItems="start"
                    pt="10px"
                    pb="10px"
                    px="15px"
                    borderRadius="md"
            
                    bg={isActive("/plan-and-billing") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Wallet color={ isActive("/plan-and-billing") ? activeColor : inactiveColor} />
                    <Text color="grey.100" ml="11px" fontSize="19px" opacity={0.9} transition="opacity 0.3s" textAlign="start">
                        {visibleTexts.plan}
                    </Text>
              
                </Box>
            </Link>
            
            <Link to="/settings" style={{display: "block", minWidth: "100%"}}>
                <Box 
                    display="flex"
                    alignItems="start"
                    pt="10px"
                    pb="10px"
                    px="15px"
                    borderRadius="md"
                    bg={isActive("/settings") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500" , cursor: "pointer"}}
                >
                    <Settings color={ isActive("/settings") ? activeColor : inactiveColor} />
               
                    <Text color="grey.100" ml="11px" fontSize="19px" opacity={0.9} transition="opacity 0.3s">
                        {visibleTexts.settings}
                    </Text>
                </Box>
            </Link>
        </VStack>
    );
};

export default SidebarMenu;
