import React from "react";
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    Input,
    Select,
    VStack,
} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { ProfileFormProps } from "./interfaces";


const ProfileForm: React.FC<ProfileFormProps> = ({ onSubmit, register, errors, handleCancel, isSubmitting  }) => {
    const { t } = useTranslation();

    return (
        <Box as="form" onSubmit={onSubmit} maxW="900px" mx="auto" py={8} bg="white" display="flex" flexDir="column" alignItems="flex-start">
            <VStack spacing={4} align="strech" w="100%">

                <Box 
                    display="flex"
                    alignItems="start"
                    justifyContent="start"
                    flexDir="column"
                >
                    <FormControl isInvalid={!!errors.name} mb={4} minW="100%">
                        <Input type="text" placeholder={t('name')} {...register("name")}  height="45px" />
                        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                    </FormControl>
                        
                    <FormControl mb={4}>
                        <Input type="email" placeholder={t('email')} {...register("email")} isDisabled   height="45px"/>
                    </FormControl>

                    <FormControl isInvalid={!!errors.name} mb={4}>
                        <Input type="password" placeholder={t('oldPassword')} {...register("old_password")}  height="45px" />
                        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                    </FormControl>

            
                    <FormControl  mb={4}>
                        <Input type="password" placeholder={t('newPassword')} {...register("new_password")} height="45px"/>
                    </FormControl>

             
                    <FormControl>
                        <Select {...register("language")} height="45px">
                            <option value="es">{t('spanish')}</option>
                            <option value="en">{t('english')}</option>
                        </Select>
                    </FormControl>
              
                </Box>
                <Flex  mt={6} >
                    <Button type="submit" colorScheme="blue" w="134px" mr="10px" isLoading={isSubmitting } > 
                        {t("save")}
                    </Button>
                    <Button variant="outline" colorScheme="gray" type="button" w="134px"  onClick={handleCancel}>
                        {t("cancel")}
                    </Button>
                  
                </Flex>
            </VStack>
        </Box>
    );
};

export default ProfileForm;
