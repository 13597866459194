import { useLogto } from '@logto/react';
import './App.css'
import AppRoutes from './router/AppRouter.tsx'
import { useCheckTokenExpiration } from './hooks/useCheckTokenExpiration.tsx';
import { useGetAccessToken } from './hooks/useGetAccessToken.tsx';
import { useEffect } from 'react';
import { authStore } from './store/authStore.tsx';
import i18n from './translator/i18n.ts';

interface CustomData {
    language?: string;
}

function App() {
    const { isAuthenticated, fetchUserInfo } = useLogto();
    const { setUserName, setInfoUser, setLanguage } = authStore.getState();
    const { isTokenExpired } = useCheckTokenExpiration();
    const { language } = authStore((state) => state);

    useGetAccessToken();

    useEffect(() => {
        if (i18n.language !== language) {
            i18n.changeLanguage(language);
        }
    }, [language]);

    useEffect(() => {
        const checkTokenAndSignOut = async () => {
            if (isAuthenticated) {
                const userInfo = await fetchUserInfo();
                setInfoUser({
                    email: userInfo?.email || ""
                })
                const customData = userInfo?.custom_data as CustomData;
                const preferredLanguage = customData?.language || navigator.language;
                const normalizedLanguage = preferredLanguage.includes("-ES") ? "es" : preferredLanguage.split("-")[0] === "es" ? "es" : "en";
                if (i18n.language !== normalizedLanguage) {
                    i18n.changeLanguage(normalizedLanguage)
                    setLanguage(normalizedLanguage);
                }
                setUserName(userInfo?.name || "");
  
            }
        };

        checkTokenAndSignOut()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, isTokenExpired]);
    
    return (
        <>
            <AppRoutes/>
        </>
    )
}

export default App

