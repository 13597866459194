import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import CenteredContainer from "./CenterContainer/indext";
import SidebarMenu from "./SidebarMenu";
import { Box, Flex, IconButton } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const Layout: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(true);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    
    return (
        <>
            <Navbar />
            <CenteredContainer>
                <Flex width="100%">
                    <Box  width={isMenuOpen ? "275px" : "100px"}
                        transition="width 0.4s ease"
                        position="relative" >
                        <SidebarMenu isMenuOpen={isMenuOpen} />
                        <IconButton
                            icon={isMenuOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                            onClick={toggleMenu}
                            position="absolute"
                            top="50px"
                            right="-15px"
                            size="sm"
                            aria-label="Toggle Menu"
                            bg="white"
                            boxShadow="md"
                        />
                    </Box>
                    <Box flex="1" pt="25" p="48px" bg="white.50"  borderRadius="xl" minHeight="100vh">
                        <Outlet />
                    </Box>
                </Flex>
            </CenteredContainer>

        </>
    );
};

export default Layout;
