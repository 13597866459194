import {useLogto} from "@logto/react";
import {authStore} from "../store/authStore";
import {useEffect} from "react";
export const currentSite = 'Assist';

export const useGetAccessToken = () => {
    const { isAuthenticated, getAccessToken } = useLogto();
    const { accessToken, setAccessToken } = authStore.getState();

    useEffect(() => {
        const fetchToken = async () => {
            if (isAuthenticated && !accessToken) {
                const token = await getAccessToken(import.meta.env.VITE_LOGTO_API_ID);
                console.log("TOKEN", token)
                setAccessToken(token || '');
            }
        };

        fetchToken();
    }, [accessToken, getAccessToken, isAuthenticated, setAccessToken]);

    return accessToken;
};