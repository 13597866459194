import { useLogto } from "@logto/react";
import { useEffect, useState } from "react";
import { authStore } from "../store/authStore.tsx";

export const useCheckTokenExpiration = () => {
    const { isAuthenticated } = useLogto();
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const { accessToken } = authStore.getState();
    const [timeUntilExpiration, setTimeUntilExpiration] = useState<number | null>(null);

    useEffect(() => {
        const checkExpiration = () => {
            if (!isAuthenticated || !accessToken) {
                setIsTokenExpired(true);
                setTimeUntilExpiration(null);
                return;
            }

            const tokenParts = accessToken.split(".");
            if (tokenParts.length !== 3) {
                setIsTokenExpired(true);
                setTimeUntilExpiration(null);
                return;
            }

            const payload = JSON.parse(atob(tokenParts[1]));
            const exp = payload.exp * 1000;
            const currentTime = Date.now();

            if (currentTime >= exp) {
                setIsTokenExpired(true);
                setTimeUntilExpiration(null);
            } else {
                setIsTokenExpired(false);
                setTimeUntilExpiration(Math.ceil((exp - currentTime) / 1000));
            }
        };

        checkExpiration();

        // Change the interval to 60 seconds, or adjust based on token duration
        const interval = setInterval(checkExpiration, 60000);
        return () => clearInterval(interval);
    }, [accessToken, isAuthenticated]);

    return { isTokenExpired, timeUntilExpiration };
};
